import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap"
import 'react-modal-video/scss/modal-video.scss';
import { createPortal } from 'react-dom';
// import {Link}  from "gatsby"
import ReactPlayer from 'react-player'
// import { useLocation } from "@reach/router"
import $ from "jquery"

import {
    isIOS,
    isMobile
} from "react-device-detect";

// const ModalWindow = ({ children }) => {
//     // element to which the modal will be rendered
//     const el = document.createElement("div");
//     // add a class to the container div for styling
//     // el.className = "modal-portal-container";
//     useEffect(() => {
//         const modalRoot = document.body;
//         // append to root when the children of Modal are mounted
//         modalRoot.appendChild(el);
//         // do a cleanup
//         return () => {
//             modalRoot.removeChild(el);
//         };
//     }, [el]);

//     return(
//         createPortal(
//             children,
//             el
//         )
//     )
// }


const CustomVideo = (props) => {
    // const [isOpen, setOpen] = useState(false);

     const trackerVideo = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Video Play Btn',
          'formType': event,
          'formId': 'Video Play',
          'formName': 'Video',
          'formLabel': 'Video'
        });
    }

//   const [isPlay, setPlay] = useState(false);
  const vidRef = useRef(null);
  const [show, setShow] = useState(false);
  const [play, setPlayvideo] = useState(true);
  const [mute, setMute] = useState(true);
  const [controls, setVideoControls] = useState(false);


  const [showHTMLVideo, setHTMLVideo] = React.useState(false);
  const pausevideo = (ref) => {
    setPlayvideo(false)
    setVideoControls(false)

  }
  const playvideo = (ref) => {
    setPlayvideo(true)
    setVideoControls(false)
    setTimeout(function(){ $(".modal-bg-black").hide() }, 1700);
  }
  const showControls = () => {
    setVideoControls(true)
  }

    return(
        <>

            <strong className="video-btn"  onClick={() => { setHTMLVideo(true); playvideo(); setShow(true); }}>
                <i className="icon-video"></i>
            </strong>

            {showHTMLVideo  &&
                <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-video"
                aria-labelledby="example-custom-modal-styling-title"
                backdropClassName="video-backdrop"
                className="full-video-popup"
                >
                <Modal.Header closeButton>
                </Modal.Header>
                    <Modal.Body>
                    {isMobile &&
                    <div className="modal-bg-black">
                    </div>
                    }
                    <ReactPlayer frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen autoplay ref={vidRef} onPlay={trackerVideo(props.title)} url={props.url} modestbranding="1"  controls={isMobile && isIOS ?true:false} autoplay={true} muted={isMobile && isIOS ? mute : false} playsinline={true} playing={play} onEnded={() => { setHTMLVideo(false) }} width='100%' height='100vh'  previewTabIndex={0} light={false}/>
                    </Modal.Body>
                </Modal>

            }

        </>
    )
}

export default CustomVideo

