import React, { useState } from "react";
import { Link } from "@StarberryUtils";
import { defaultArea } from "@constants";
import { Container, Row, Col } from "react-bootstrap";
import ModalTiny from "react-bootstrap/Modal"
import Slider from "react-slick";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useLocation } from "@reach/router"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import { handleBackToListing } from "../common/site/utils";
import "./PropertyBanner.scss";

import PlayVideo from "../Play/custom-video";
import BookViewForm from "../forms/book-a-viewing-form"
import ShareFrndForm from "../forms/send-friend-form"
import Negotiator from "./PropertyNegotiator"
import BookViewing from "./BookViewing"

import ScrollAnimation from "react-animate-on-scroll";
// Header component

const PropertyBanner = (props) => {
  // Property details image count
  const [ indexed, setIndex ] = useState(1);

  const afterChangeHandler = (d) => {
    setIndex(d+1)
  }
  // Property details image count

  // Property images lightbox
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openPropertyImage = (e, ind) => {
    e.preventDefault();
    setPhotoIndex(ind);
    setIsOpen(true);
  }
  // Property images lightbox



  const [modalSendfrndformOpen,setSendfrndformOpen] = React.useState(false);
  const openSendfrndformModal = () => {
    setSendfrndformOpen(true);
  }
  const closeSendfrndformModal = () => {
    setSendfrndformOpen(false);
  }

  const [modalSharefrndformOpen,setSharefrndformOpen] = React.useState(false);
  const openSharefrndformModal = () => {
    setSharefrndformOpen(true);
  }
  const closeSharefrndformModal = () => {
    setSharefrndformOpen(false);
  }

  const [isPlay, setPlay] = useState(false);
  const settings = {
    dots: false,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1402,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 377,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const propertyImages = props.propertyData.images

  // ggfx
  let processedImages = JSON.stringify({});
  if (props.propertyData?.imagetransforms?.images_Transforms) {
      processedImages = props.propertyData.imagetransforms.images_Transforms;
  }
  // ggfx

  // Property details images lightbox
  var propertyLightImages = propertyImages && propertyImages.map(img => img.url);
  // Property details images lightbox

  //
  let uriStr = "";
  if(props.propertyData.search_type === "sales") {
    uriStr = `/property-for-sale/in-${defaultArea.slug}/`
  } else if (props.propertyData.search_type === "lettings") {
    uriStr = `/property-to-rent/in-${defaultArea.slug}/`
  }

  if(props.propertyData.status === "sold") {
    uriStr = "/sell/sold-gallery"
  }
  // else if(props.propertyData.status === "To Let") {
  //   uriStr = "/landlords/let-gallery"
  // }
  //

  // Social share
  const location = useLocation();

  const shareurl = typeof window !== 'undefined' ? window.location.href : ''

  const [Shareicons,setShareicons] = React.useState(false);

  const openShareicons = () => {
    setShareicons(true);
    if(Shareicons === true) {
      setShareicons(false);
    }
  }

  const [modalBookViewformOpen,setBookViewingformOpen] = React.useState(false);

  const openBookViewformModal = () => {
      setBookViewingformOpen(true);
  }

  const trackerShare = (event) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Share - social icons',
      'formType': event + ' - ' +shareurl,
      'formId': 'Share',
      'formName': 'Share',
      'formLabel': 'Share'
    });
  }
  // Social share

  if(propertyImages) {
    var img_1 = propertyImages[0].srcUrl || propertyImages[0].url
  }

  const price_qualifier = props?.propertyData?.price_qualifier || 'Price from '

  return (
    <React.Fragment>
      <section className="banner-slider new-homes">

      <div className="slider-wrapper">
        <div className="slider">
          <div className="img-wrapper">
            <Slider {...settings}
              afterChange = {
                v => afterChangeHandler(v)
              }
            >
              {
                propertyImages && propertyImages.map((node, i) => {
                  let processedImages = JSON.stringify({});
                  if (props.propertyData?.imagetransforms?.Images_Transforms) {
                      processedImages = props.propertyData.imagetransforms.Images_Transforms;
                  }

                  return (
                    <div className="banner-img img-zoom">
                      <ImageTransform
                        imagesources={node.url}
                        renderer="srcSet"
                        imagename="new-developments.images.details"
                        attr={{ alt: '', class:'' }}
                        imagetransformresult={processedImages}
                        id={props.propertyData.id}
                      />
                        {
                          props.propertyData.virtual_tour && i == 0 &&
                          <div className="video-buttons ">
                            <PlayVideo url={props.propertyData.virtual_tour} />
                          </div>
                        }
                    </div>
                  )
                })
              }
            </Slider>
            <div className="property-slider-count-wrapper d-flex align-items-center justify-content-center">
              <ul className="list-inline d-flex align-items-center" onClick={(e) => openPropertyImage(e, 0)}>
                <li className="list-inline-item">
                  <a href="javascript:void(0)" onClick={(e) => openPropertyImage(e, 0)}><i className="icon-zoom-property"></i></a>
                </li>
                <li className="list-inline-item line-divider"></li>
                <li className="list-inline-item">{indexed}/{props.propertyData.images.length}</li>
              </ul>
            </div>
            {props.propertyData.status === "sold" ? <div className="flag">SOLD</div> : ''}
            {props.propertyData.status === "let" ? <div className="flag">LET</div> : ''}
          </div>
        </div>
          {/* Property image lightbox */}
          {isOpen && (
            <Lightbox
              mainSrc={propertyLightImages[photoIndex]}
              nextSrc={propertyLightImages[(photoIndex + 1) % propertyLightImages.length]}
              prevSrc={propertyLightImages[(photoIndex + propertyLightImages.length - 1) % propertyLightImages.length]}
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() =>
                setPhotoIndex((photoIndex + propertyLightImages.length - 1) % propertyLightImages.length)
              }
              onMoveNextRequest={() =>
                setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
              }
            />
          )}
          {/* Property image lightbox */}
        </div>

        <Container className="new-homes-one">
            <Row>
                <Col lg={12}>
                <div className="banner-card">
                <div className="sm-info">
                    <a href="javascript:void(0)" onClick={(e) => { e.preventDefault();handleBackToListing(props.propertyData)}}>
                        <i className="icon-back"></i>
                        Back to Listings
                    </a>
                </div>
                </div>
              </Col>
                <Col lg={8}>
          <div className="banner-card">

            <div className="banner-content">
              <h1>{props.propertyData && props.propertyData.display_address}</h1>
              <span className="sm-text">
              {price_qualifier && <span className="price-qualifier">{price_qualifier}&nbsp;</span>}
              £{props.propertyData?.price && props.propertyData.price.toLocaleString()}
              </span>
            </div>
            <div className="card-info">
              {
                props.propertyData.title && props.propertyData.bedroom>0 &&
                <span className="details">
                  <Link href="javascript:void(0)">
                  <i className="icon-chair"></i>
                  </Link>
                  {props.propertyData.title}
                </span>
              }

            </div>
            </div>
                </Col>
                <Col lg={4}>
                <div className="banner-card">
                <div className="btn-wrapper social-share-wrapper">
                    <a href="javascript:void(0)" className="btn btn-viewing btn_black" onClick={openSendfrndformModal}>
                        Enquire now
                    </a>
                    <a href="javascript:void(0)" onClick={openShareicons} className="btn share d-inline-block d-md-none">
                        <i className="icon-share"></i> Share
                    </a>
                    {Shareicons &&
                        <div onClick={openShareicons} className="property-share-social-icons mobile-details-socila-share">
                        <FacebookShareButton onClick={()=>trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share">
                            <FacebookIcon size={32} round={false} borderRadius={`10`} />
                        </FacebookShareButton>
                        <TwitterShareButton onClick={()=>trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share">
                            <TwitterIcon size={32} round={false} borderRadius={`10`} />
                        </TwitterShareButton>
                        <LinkedinShareButton onClick={()=>trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share">
                            <LinkedinIcon size={32} round={false} borderRadius={`10`} />
                        </LinkedinShareButton>
                        <WhatsappShareButton onClick={()=>trackerShare('WhatsappShareButton')} url={shareurl} className="my-share-button whatsapp-share">
                            <WhatsappIcon size={32} round={false} borderRadius={`10`} />
                        </WhatsappShareButton>
                        <EmailShareButton url='#' onClick={()=> { openSharefrndformModal();trackerShare('SendToFriend')}} className="my-share-button email-share">
                            <EmailIcon size={32} round={false} borderRadius={`10`} />
                        </EmailShareButton>
                        </div>
                    }
            </div>
            {/* <Negotiator id={props.propertyData.office_crm_id}/> */}
            </div>

                </Col>
            </Row>
        </Container>

        <ModalTiny show={modalSendfrndformOpen} onHide={closeSendfrndformModal} className="book-a-viewing-modal getin-touch">
          <ModalTiny.Header closeButton className="contact-close-btn">
            <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">Book a Viewing</h2></ModalTiny.Title>
          </ModalTiny.Header>

          <ModalTiny.Body className="event-section">
            {props.propertyData.office_crm_id ?
            <BookViewing id={props.propertyData.office_crm_id} property_img={img_1} page_url={shareurl} property_title={props.propertyData.display_address} type={props.propertyData.search_type}/> :
            <BookViewForm to_email_id={props.propertyData?.office?.New_Homes_Email || process.env.GATSBY_MAIL_NEWHOME_TO} property_img={img_1} page_url={shareurl} property_title={props.propertyData.display_address}/> }
          </ModalTiny.Body>

          </ModalTiny>


          <ModalTiny show={modalSharefrndformOpen} onHide={closeSharefrndformModal} className="book-a-viewing-modal">
          <ModalTiny.Header closeButton className="contact-close-btn">
            <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">SHARE TO A FRIEND</h2></ModalTiny.Title>
          </ModalTiny.Header>

          <ModalTiny.Body className="event-section">
            <ShareFrndForm property_img={img_1} page_url={shareurl} property_title={props.propertyData.display_address}/>
          </ModalTiny.Body>

          </ModalTiny>

      </section>
    </React.Fragment>
  );
};

export default PropertyBanner;
