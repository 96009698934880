import React, { useState, useEffect, useRef } from "react";
import { Link } from "@StarberryUtils";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import ModalTiny from "react-bootstrap/Modal"
import Slider from "react-slick";
import Lightbox from 'react-image-lightbox';
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import 'react-image-lightbox/style.css';
import parse from "html-react-parser"
import $ from "jquery";
import { useLocation } from "@reach/router"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import PlayVideo from "../../Play/custom-video2";
import ShareFrndForm from "../../forms/send-friend-form"
import SimilarProperties from "../../ProperyDetails/SimilarProperties/AvailableProperties"
import "./Details.scss";



// Header component

const SIMILAR_DEV_PROP = gql`
query SIMDEVPROP($URL: String!){
    properties(where:{new_development:{id_eq: $URL}, publish: true}, sort: "building:asc"){
        search_type
        status
        images
        display_address
        _id
        id
        slug
        price
        price_qualifier
        bedroom
        building
        bathroom
        reception
        imagetransforms
        new_development {
          id
        }
    }
}
`;

function Details(props) {
    const { loading, error, data } = useQuery(SIMILAR_DEV_PROP, {
        variables: { URL: props.propertyData.id }
    });

    const stickyRef = useRef();

    // Floorplan slider
    const [isPlay, setPlay] = useState(false);
    const settings = {
        dots: false,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1402,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 377,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const propertyImages = props.propertyData.images

    const [modalSharefrndformOpen,setSharefrndformOpen] = React.useState(false);
    const openSharefrndformModal = () => {
        setSharefrndformOpen(true);
    }
    const closeSharefrndformModal = () => {
        setSharefrndformOpen(false);
    }

    const floorplanImages = props.propertyData.floorplan
    // Floorplan slider

    // ggfx
    let processedImages = JSON.stringify({});
    if (props.propertyData?.imagetransforms?.images_Transforms) {
        processedImages = props.propertyData.imagetransforms.images_Transforms;
    }
    // ggfx

    // Property floorplan images lightbox
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const openPropertyImage = (e, ind) => {
        e.preventDefault();
        setPhotoIndex(ind);
        setIsOpen(true);
    }

    var propertyLightImagesFloorplan = floorplanImages && floorplanImages.map(img => img.url);
    // Property floorplan images lightbox

    // link to scroll
    const handleLinkTo = (e, target) => {
        e.preventDefault();
        let topHeight = document.getElementById('pageheader').offsetHeight;
        if (target === '#property-details-map')
            topHeight = topHeight + 70;
        $('html, body').animate({
            scrollTop: $(target).offset().top - topHeight
        }, 1000);
    }

    useEffect(() => {
        var propertySelector = '.property-details-menu-wrapper li a';

        $(propertySelector).on('click', function(){
            $(propertySelector).removeClass('active');
            $(this).addClass('active');
        });

        // $(".propertyFeatures").click(function() {
        //     $('html, body').animate({
        //         scrollTop: $("#key-features").offset().top-70
        //     }, 1000);
        // })

        // $(".propertyPlans").click(function() {
        //     $('html, body').animate({
        //         scrollTop: $("#new-plans").offset().top-70
        //     }, 1000);
        // })

        // $(".propertyAvailability").click(function() {
        //     $('html, body').animate({
        //         scrollTop: $("#property-availability").offset().top-70
        //     }, 1000);
        // })

        // $(".propertyDetails").click(function() {
        //     $('html, body').animate({
        //         scrollTop: $("#property-details-description").offset().top-70
        //     }, 1000);
        // })

        // $(".propertyMap").click(function() {
        //     $('html, body').animate({
        //         scrollTop: $("#property-details-map").offset().top - 70
        //     }, 1000);
        // })
    }, [data]);

    // Social share
    const location = useLocation();

    const shareurl = typeof window !== 'undefined' ? window.location.href : ''

    const [Shareicons,setShareicons] = React.useState(false);

    const openShareicons = () => {
        setShareicons(true);
        if(Shareicons === true) {
        setShareicons(false);
        }
    }

    const [modalBookViewformOpen,setBookViewingformOpen] = React.useState(false);

    const openBookViewformModal = () => {
        setBookViewingformOpen(true);
    }

    const trackerShare = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
        'event': 'Share - social icons',
        'formType': event + ' - ' +shareurl,
        'formId': 'Share',
        'formName': 'Share',
        'formLabel': 'Share'
        });
    }
    // Social share

    if(propertyImages) {
        var img_1 = propertyImages[0].srcUrl
    }


    if (loading) return null;

    return (
        <React.Fragment>
            <section>
                <section className="details-section">
                    <div className="new-home-head sticky-header">
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <ul className="list-inline property-details-menu-wrapper">
                                    {
                                        props.propertyData.description &&
                                        <li className="list-inline-item">
                                            <a href="javascript:void(0)" onClick={(e) => handleLinkTo(e, '#key-features')} className="active propertyFeatures">Details</a>
                                        </li>
                                    }
                                    {
                                        props.propertyData.floorplan?.length > 0 &&
                                        <li className="list-inline-item">
                                            <a href="javascript:void(0)" onClick={(e) => openPropertyImage(e, 0)} className=" propertyPlans">Plans</a>
                                        </li>
                                    }
                                    {
                                        data.properties.length > 0 &&
                                        <li className="list-inline-item">
                                            <a href="javascript:void(0)" onClick={(e) => handleLinkTo(e, '#property-availability')} className="propertyAvailability">Availability</a>
                                        </li>
                                    }
                                    {
                                        props.propertyData.virtual_tour &&
                                        <li className="list-inline-item">
                                            <PlayVideo url={props.propertyData.virtual_tour} />
                                        </li>
                                    }
                                    {
                                        props.propertyData.latitude &&
                                        <li className="list-inline-item">
                                            <a href="javascript:void(0)" onClick={(e) => handleLinkTo(e, '#property-details-map')} className="propertyMap">Map</a>
                                        </li>
                                    }
                                    {props.propertyData?.brochure?.url && (
                                        <li className="list-inline-item">
                                            <a href={props.propertyData?.brochure?.url} target="_blank" className="">Brochure</a>
                                        </li>
                                    )}
                                    <li className="list-inline-item social-share-wrapper2">
                                        <a href="javascript:void(0)" className="propertyShare" onClick={openShareicons}>Share</a>
                                        {Shareicons &&
                                            <div onClick={openShareicons} className="property-share-social-icons2 mobile-details-socila-share">
                                            <FacebookShareButton onClick={()=>trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share">
                                                <FacebookIcon size={32} round={false} borderRadius={`10`} />
                                            </FacebookShareButton>
                                            <TwitterShareButton onClick={()=>trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share">
                                                <TwitterIcon size={32} round={false} borderRadius={`10`} />
                                            </TwitterShareButton>
                                            <LinkedinShareButton onClick={()=>trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share">
                                                <LinkedinIcon size={32} round={false} borderRadius={`10`} />
                                            </LinkedinShareButton>
                                            <WhatsappShareButton onClick={()=>trackerShare('WhatsappShareButton')} url={shareurl} className="my-share-button whatsapp-share">
                                                <WhatsappIcon size={32} round={false} borderRadius={`10`} />
                                            </WhatsappShareButton>
                                            <EmailShareButton url='#' onClick={()=> { openSharefrndformModal();trackerShare('SendToFriend')}} className="my-share-button email-share">
                                                <EmailIcon size={32} round={false} borderRadius={`10`} />
                                            </EmailShareButton>
                                            </div>
                                        }
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                    <div className="separation-line"></div>
                </div>

                               

                    <Container>
                        <Row>
                            <Col sm={12}>

                                <div className="tab-content tab-content-details new-home-details" id="key-features">
                                    <Row>
                                        <Col lg={3}>
                                            {
                                                props.propertyData.description?.length > 0 &&
                                                <h1><i>Development</i> details</h1>
                                            }
                                        </Col>
                                        <Col lg={6}>
                                            <div className="tab-details">
                                                {props.propertyData?.search_description && (
                                                    <p>{parse(props.propertyData.search_description)}</p>
                                                )}
                                                {props.propertyData?.description && (
                                                    parse(props.propertyData.description)
                                                )}
                                                {props.propertyData?.Additional_Description && (
                                                    parse(props.propertyData.Additional_Description)
                                                )}
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                        <Slider {...settings}>
                                            {
                                                floorplanImages && floorplanImages.map((node, i) => {
                                                let processedImages = JSON.stringify({});
                                                if (props.propertyData?.imagetransforms?.images_Transforms) {
                                                    processedImages = props.propertyData.imagetransforms.images_Transforms;
                                                }

                                                    return (
                                                        <div className="tab-img">
                                                            <ImageTransform
                                                                imagesources={node.url}
                                                                renderer="srcSet"
                                                                imagename="property.images.floorplan"
                                                                attr={{ alt: '', class:'' }}
                                                                imagetransformresult={processedImages}
                                                                id={props.propertyData.id}
                                                            />
                                                            <a href="javascript:void(0)" onClick={(e) => openPropertyImage(e, 0)}>
                                                                <i className="icon-zoom">
                                                                </i>
                                                            </a>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Slider>
                                            </Col>
                                    </Row>
                                </div>

                                {
                                        data.properties.length > 0 &&
                                    <div className="" id="property-availability">
                                    <Row>
                                        <Col lg={12}>
                                    <SimilarProperties data={data.properties}/>
                                    </Col>
                                    </Row>
                                    </div>
                                    }
                            </Col>
                        </Row>
                    </Container>
                </section>
                </section>

                {/* Property floorplan image lightbox */}
                {isOpen && (
                    <Lightbox
                        mainSrc={propertyLightImagesFloorplan[photoIndex]}
                        nextSrc={propertyLightImagesFloorplan[(photoIndex + 1) % propertyLightImagesFloorplan.length]}
                        prevSrc={propertyLightImagesFloorplan[(photoIndex + propertyLightImagesFloorplan.length - 1) % propertyLightImagesFloorplan.length]}
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + propertyLightImagesFloorplan.length - 1) % propertyLightImagesFloorplan.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % propertyLightImagesFloorplan.length)
                        }
                    />
                )}
                {/* Property floorplan image lightbox */}



          <ModalTiny show={modalSharefrndformOpen} onHide={closeSharefrndformModal} className="book-a-viewing-modal">
          <ModalTiny.Header closeButton className="contact-close-btn">
            <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">SHARE TO A FRIEND</h2></ModalTiny.Title>
          </ModalTiny.Header>

          <ModalTiny.Body className="event-section">
            <ShareFrndForm property_img={img_1} page_url={shareurl} property_title={props.propertyData.display_address}/>
          </ModalTiny.Body>

          </ModalTiny>
        </React.Fragment>
    );
};

export default Details;
