import React, { useState } from "react"
import { groupBy, isEmpty, orderBy } from "lodash"
import { Link } from "@StarberryUtils"
import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap"
import ImageTransform from "../../common/ggfx-client/module/components/image-transform"

import Slider from "react-slick"
import "./SimilarProperties.scss"
import ScrollAnimation from "react-animate-on-scroll"
import PriceComp from "@components/Property/Price"
import Coming from "../../../images/coming-soon.png"
// Header component

let buildingTypes = {
    "apartment" : "Apartments",
    "detached-house" : "Detached Houses",
    "semi-detached-house": "Semi Detached Houses",
    "terraced-house": "Terraced Houses",
    "bungalow": "Bungalows",
    "other" : "other",
    "flat": "Flats"
}

const Properties = props => {
console.log('sampleData', props)
  if (isEmpty(props?.data))
    return <></>

  let groupedItem = groupBy(props?.data || [], "building")

  let groupedItemkey = Object.keys(groupedItem);
  let otherIndex = groupedItemkey.indexOf("other");
  if (otherIndex) {
      let otherItems = groupedItem['other'];
      if (otherItems) {
          groupedItemkey.splice(otherIndex, 1)
          groupedItemkey.push('other')
      }
  }

  const settings = {
    dots: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 2,
    infinite: false,
    arrows: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: { 
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },

      {
        breakpoint: 991,
        settings: { 
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },

      {
        breakpoint: 767,
        settings: {  
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <React.Fragment>
      <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
        <section className="similar-properties available-props">
          <Container>
              <h2><i>Development</i> Availability</h2>
            <div className="avail-main-wrap">
              {groupedItemkey.map(key => {
                let items = groupedItem[key]
                // let groupedItem2 = groupBy(orderBy(items, "bedroom"), "bedroom")
                let groupedItem2 = groupBy(items, "bedroom")
                let groupedItem2Key = Object.keys(groupedItem2)
                return groupedItem2Key.map(index => {
                  let properties = groupedItem2[index]
                  let keyText = key;
                  if (!isEmpty(buildingTypes[key]))
                    keyText = buildingTypes[key];
                  return (
                    <>
                        <h3 className="child-group-name">
                            {(key === 'other') ?
                                <>
                                    {`${index} bedroom ${keyText.replaceAll('-', ' ')} properties`}
                                </>
                            :
                                <>
                                    {`${index} bedroom ${keyText.replaceAll('-', ' ')}`}
                                </>
                            }
                        </h3>
                        <Row className="avail-main">
                      {properties.map((item, index) => {
                        let uriStr = ""

                        if (item.search_type === "sales") {
                          uriStr = `property-for-sale-details/`
                        } else if (item.search_type === "lettings") {
                          uriStr = `property-to-rent-details/`
                        }

                        let processedImages = JSON.stringify({})
                        if (item?.imagetransforms?.images_Transforms) {
                          processedImages =
                            item.imagetransforms.images_Transforms
                        }

                        return (
                          <Col lg={4} className="property">
                            <div className="properties img-zoom">
                            <div className="search-img-wrapper">
                              <Link
                                to={"/" + uriStr + item.slug + "-" + item.id}
                              >
                                {item.images && item.images.length > 0 ? (
                                  <ImageTransform
                                    imagesources={item.images[0].url}
                                    renderer="srcSet"
                                    imagename="property.images.similar_properties"
                                    attr={{
                                      alt: item.display_address + " - Redbrik",
                                      class: "",
                                    }}
                                    imagetransformresult={processedImages}
                                    id={item._id}
                                  />
                                ) : (
                                  <img src={Coming} alt="" className="soon" />
                                )}
                              </Link>
                              <div className="search-img-overlay-flag">
                                  {item.status === "Sold STC" ? <div className="flag">SOLD STC</div> : ''}
                                  {item.status === "Let Agreed" ? <div className="flag">LET AGREED</div> : ''}
                                  {item.status === "sold" ? <div className="flag">SOLD</div> : ''}
                                  {item.status === "let" ? <div className="flag">LET</div> : ''}
                              </div>
                              </div>

                              <div className="card-details">
                                <h3>
                                  <Link
                                    to={
                                      "/" + uriStr + item.slug + "-" + item.id
                                    }
                                  >
                                    {item.display_address}
                                  </Link>
                                </h3>
                                {item.price && (
                                  <h4>
                                    <PriceComp {...item} />
                                  </h4>
                                )}
                                <div className="card-info">
                                  {item.bedroom && (
                                    <span className="details">
                                      <Link href="javascript:void(0)">
                                        <i className="icon-bedroom"></i>
                                      </Link>
                                      {item.bedroom}
                                    </span>
                                  )}
                                  {item.bathroom && (
                                    <span className="details">
                                      <Link href="javascript:void(0)">
                                        <i className="icon-bathroom"></i>
                                      </Link>
                                      {item.bathroom}
                                    </span>
                                  )}
                                  {item.reception && (
                                    <span className="details">
                                      <Link href="javascript:void(0)">
                                        <i className="icon-chair"></i>
                                      </Link>
                                      {item.reception}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Col>
                        )
                      })}
                      </Row>
                    </>
                  )
                })
              })}
            </div>
          </Container>
        </section>
      </ScrollAnimation>
    </React.Fragment>
  )
}

export default Properties
